$(window).on('scroll', function() {
  var $elemList = $('.wait_animated');
  var $window = $(window);
  var docViewTop = $window.scrollTop();
  var docViewBottom = docViewTop + $window.height();
  var scroll = $(window).scrollTop();

  $.each($elemList, function(ind, el) {
    if($(el).offset()) {
      var elemTop = $(el).offset().top;
      var animationOffset = $(el).attr('data-animation-offset') || 100;
      var elemBottom = elemTop + $(el).height() - parseInt(animationOffset, 10);

      if (elemBottom < docViewBottom) {
        var animationType = $(el).attr('data-animation-type');
        var animationDelay = $(el).attr('data-animation-delay');
        if(animationDelay) {
          animationDelay = 'db-'+animationDelay;
        }
        var animationDuration = $(el).attr('data-animation-duration');
        $(el).removeClass('wait_animated');
        $(el).addClass('animated');
        $(el).addClass(animationType);
        $(el).addClass(animationDelay);
        $(el).addClass(animationDuration);
      }
    }
  });

  if (scroll >= 100) {
    $("header").addClass("scrolled");
  } else {
    $("header").removeClass("scrolled");
  }
});

function scrollToElement(elementId) {
  var element = document.getElementById(elementId);
  if(element) {
    element.scrollIntoView();
  }
}
